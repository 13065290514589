// Styled list
//////////////////////////////
	dl.floatList {
		@include pie-clearfix;

		dt, dd {
			display: block;
			vertical-align: top;
			float: left;
		}
		dt {
			clear:left;
			width:100%;
			font-weight: 700;
			padding-right: .5rem;
			@include breakpoint(tiny) {
				width: 39%;
				font-weight: 400;
			}
			@include breakpoint(medium) {
				width:45%;
			}
			@include breakpoint(giant) {
				width:39%
			}
		}
		dd {
			width:100%;
			margin-bottom:1rem;
			@include breakpoint(tiny) {
				width: 55%;
				margin-bottom:0;
			}
		}
	}

// SOME HELPFUL CLASSES
//////////////////////////////
	.highlight {
		color: $secondary;
		font-weight: 700;
	}
	.btn {
		border: none;
		outline:none;
		color: $light;
		display: block;
		cursor: pointer;
		font-weight: 700;
		line-height: 1em;
		text-align: center;
		font-size: rem(14px);
		background: $secondary;
		font-family: $main-font;
		text-transform: uppercase;
		transition: 300ms all ease-in-out;
		padding:rem(16px) rem(10px) rem(18px) rem(10px);
		
		@include breakpoint(tiny) {
			font-size: rem(20px);
			display: inline-block;
			padding:rem(16px) rem(23px) rem(18px) rem(27px);
		}
		&:after{
			top:1px;
			content:'\f105';
			position: relative;
			font-size: rem(20px);
			margin-left:rem(9px);
			font-family: $icon-font;
			@include breakpoint(tiny) {
				font-size: rem(25px);
			}
		}
		&:hover, &:active, &:focus {
			background: $dark;
			color: $light;
		}

		*[class^="icon"] {
			margin-left: .5rem;
			vertical-align: middle;
		}
	}
	.notification {
		padding: 1em;
		background: $alert;
		color: $light;
		font-size: 1.2em;
	}

// PAGE WRAP
//////////////////////////////
	.page-wrap {
		overflow:hidden;
		transition: transform 0.3s ease-in-out;
		@include breakpoint(large) {
			transform: none;
			transition: none;
		}
	}

// HEADER
//////////////////////////////
	.branding {
		display: block;
		margin: 0 auto;
		max-width: rem(194px);
	}
	.header {
		background: $light;
		position: relative;
		width: 100%;
		@include breakpoint(large) {
			margin-top:rem(125px);
		}
		@include breakpoint(giant) {
			margin-top:rem(124px);
		}
		.branding{
			margin:rem(55px) auto rem(35px);
			@include breakpoint(large) {
				display: none;
			}
		}
		.top-row{
			color:$light;
			display: none;
			line-height: normal;
			background-color: $dark;
			padding:rem(28px) 0 rem(24px);
			@include breakpoint(medium) {
				display: block;
			}
			span{
				display: block;
			}
			.phone-wrapper{
				display: none;
				font-family: $accent-black;
				position: relative;
				@include breakpoint(giant) {
					display: block;
				}
				&:before{
					content:'';
					height:100%;
					width:1px;
					background-color: rgba($light, .22);
					@include position(absolute, rem(-2px) rem(27px) null null);
				}
				.phone-big{
					position: relative;
					display: inline-block;
					text-transform: uppercase;
					&:before{
						width:rem(33px);
						height:rem(75px);
						@include position(absolute, rem(-2px) rem(-57px) null null);
						background: image-url('layout/arrow.png') center center no-repeat;
						background-size: contain;
						@include breakpoint(giant) {
							content: '';
						}
					}
					.text{
						line-height: 0.8em;
						font-size: rem(52px);
						margin-bottom:rem(6px);
					}
					.number{
						line-height: 0.8em;
						color:$secondary;
						font-size: rem(35px);
					}
				}
				.termin{
					display: inline-block;
					text-transform: uppercase;
					@include breakpoint(giant) {
						margin-left:rem(67px);
					}
					.termin-white{
						line-height: 0.8em;
						font-size: 30px;
						font-family: $accent-bold;
					}
					.termin-blue{
						color:$secondary;
						line-height: 0.8em;
						letter-spacing: 1px;
						font-size: 30px;
					}
				}
			}
			.openings, .address{
				font-size: rem(15px);
				line-height: 1.6em;
				a{
					color:#7fc5f4;
					&:hover, &:focus {
						color:$light;
					}
				}
			}
			.weekly, .weekend{
				display: inline-block;
			}
			.weekend{
				float: right;
			}
		}
		.mainSlider, .mobile-img{
			display: none;
			@include breakpoint(medium) {
				display: block;
			}
			.slide{
				outline:none;
				padding:rem(50px) 0 rem(40px);
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				@include breakpoint(medium) {
					min-height:rem(669px);
					padding:rem(178px) 0 rem(40px);
				}
				.slide-text{
					font-weight: 500;
					text-transform: uppercase;
					font-family: $display-font;
					&.light{
						p{
							color:$light;
						}
					}
					.slide-headline{
						color:$light;
						line-height: 1em;
						font-size: rem(40px);
						display: inline-block;
						background-color: #000;
						margin-bottom:rem(28px);
						padding: rem(12px) rem(23px) rem(16px);
						@include breakpoint(tiny) {
							font-size: rem(60px);
						}
						@include breakpoint(small) {
							font-size: rem(70px);
						}
						@include breakpoint(medium) {
							font-size: rem(80px);
						}
						body:not(.index) &{
							font-size: rem(40px);
							@include breakpoint(medium) {
								font-size: rem(80px);
							}
						}
					}
					p{
						color:$dark;
						font-size: rem(24px);
						line-height: rem(47px);
						margin-bottom:rem(58px);
						// @include breakpoint(tiny) {
						// 	font-size: rem(28px);
						// }
						@include breakpoint(small) {
							font-size: rem(30px);
						}
						@include breakpoint(medium) {
							font-size: rem(38px);
						}
					}
				}
			}
		}
		.mainSlider{
			.slide{
				display: none;
				&:first-child{
					display: block;
				}
			}
		}
		.mobile-img{
			display: block;
			@include breakpoint(medium) {
				display: none;
			}
			body:not(.index) & {
				display: block;
				.slide{
					min-height:rem(257px);
					padding:rem(77px) 0 rem(20px);
				}
			}
		}
	}

// MAIN CONTENT
//////////////////////////////
	main {
		display: block;
		padding:rem(92px) 0 rem(30px);
		background: image-url('layout/pattern.jpg') ;
		@include breakpoint(medium) {
			padding:rem(92px) 0;
		}
		body:not(.index) &{
			@include breakpoint(medium) {
				padding:rem(84px) 0;
			}
		}
		ul {
			@extend .styled-list;
		}
		p{
			margin-bottom:rem(19px)
		}
		.hr-col{
			&:last-child{
				display: none;
			}
		}
		.links-col{
			margin-bottom:1rem;
		}
		.btn{
			margin-bottom:1rem;
			@include breakpoint(medium) {
				margin-bottom:0;
			}
		}
		.accContainer{
			margin-top:rem(55px);
			.descrip-wrapper{
				@include breakpoint(medium) {
					margin-bottom:rem(14px);
					padding-right:0;
				}
				.description{
					display: block;
					font-weight: 700;
					border:1px solid $dark;
					padding:rem(15px);
					height:100%;
				}
			}
			.price-wrapper{
				margin-bottom:rem(14px);
				@include breakpoint(medium) {
					padding-left:0;
				}
				.price{
					color:$light;
					display: block;
					font-weight: 700;
					height:100%;
					padding:rem(15px);
					background-color: $dark;
				}
			}
		}
		.content-btn{
			margin-top:rem(38px);
		}
		.google-maps {
			@extend .video-container;

			@include breakpoint(medium) {
				height: rem(400px);
			}
			.anfahrt &{
				height:100%;
				iframe{
					height:100%;
				}
			}
		}
		.section-headline{
			color:$dark;
			display: block;
			font-weight: 500;
			line-height: 1.3em;
			text-transform: uppercase;
			font-family: $display-font;
			small{
				display: block;
				color:$secondary;
				font-weight: 300;
				line-height: 1.3em;
			}
		}
		.separator{
			color:$light;
			background-color: $dark;
		    margin: rem(72px) 0 0;
		    padding:rem(45px) 0;
    		@include breakpoint(medium) {
    			padding: rem(68px) 0 rem(64px);
    		}
			p{
				color:#afafaf;
				font-size: rem(16px);
				line-height: rem(24px);
				&.paragraph{
					margin-bottom:rem(27px);
				}
			}
			.phone-separator{
				display: none;
				position: relative;
				@include breakpoint(large) {
					display: inline-block;
				}
				&:before{
					width:rem(53px);
					height:rem(128px);
					transform:translateY(-50%);
					@include position(absolute, 50% rem(-95px) null null);
					background: image-url('layout/arrow-big.png') center center no-repeat;
					background-size: contain;
					@include breakpoint(giant) {
						content: '';
					}
				}
				span{
					display: block;
					&.black{
						line-height: 0.8em;
						font-size: rem(56px);
						margin:rem(7px) 0 rem(6px);
						font-family: $accent-black;
					}
					&.number{
						font-family: $accent-black;
						margin-bottom:rem(9px);
						line-height: 0.8em;
						font-size: rem(41px);
						color:$secondary;
					}
					&.bold{
						line-height: 0.8em;
						font-size: rem(99px);
						margin-bottom:rem(2px);
						font-family: $accent-bold;
					}
					&.termin{
						color:$secondary;
						font-size: rem(21px);
						line-height: 0.8em;
						font-family: $accent-black;
					}
				}
			}
			.text-container{
				display: inline-block;
				padding:0;
				@include breakpoint(large) {
					padding:0 rem($base-gap);
				}
				.headline{
					@include breakpoint(medium) {
						margin-bottom:rem(17px);
					}
				}
			}
			.headline{
				display: block;
				font-weight: 300;
				margin-bottom:rem(5px);
				line-height: 1.33em;
				font-size: rem(24px);
				text-transform: uppercase;
				font-family: $display-font;
				@include breakpoint(small) {
					font-size: rem(28px);
				}
				@include breakpoint(medium) {
					font-size: rem(35px);
				}
			}
			.right-col-wrapper{
				position: relative;
				&:before{
					width:1px;
					height:100%;
					background-color: #3f3f3f;
					@include position(absolute, 0 null null 0);
					@include breakpoint(large) {
						content:'';
					}
					@include breakpoint(giant) {
						@include position(absolute, 0 null null rem(27px));
					}
				}
			}
			.right-col{
				@include breakpoint(medium) {
					float: right;
				}
			}
		}
		.section-2{
			text-align: center;
			margin:rem(40px) 0 rem(60px);
			@include breakpoint(medium) {
				margin:rem(88px) 0 0;
			}
			.section-headline{
				text-align: center;
				font-size: rem(40px);
				margin-bottom:rem(27px);
				@include breakpoint(tiny) {
					font-size: rem(45px);
				}
				@include breakpoint(small) {
					font-size: rem(50px);
				}
				@include breakpoint(medium) {
					font-size: rem(65px);
				}
				small{
					font-size: rem(24px);
					@include breakpoint(small) {
						font-size: rem(30px);
					}
					@include breakpoint(medium) {
						font-size: rem(35px);
					}
				}
			}
			p{
				margin-bottom:rem(45px);
			}
			#content-slider{
				display: none;
				margin:rem(109px) 0 rem(111px);
				width: 100%;
				overflow: auto;
				@include breakpoint(medium) {
					display: block;
				}
				.slide{
					outline:none;
					padding:0 rem(3px);
					display: none;
					&.mTSThumbContainer{
						display: block;
					}
					img{
						width:auto;
					}
				}
			}
		}
		.section-3{
			margin-bottom:rem(26px);
			.row{
				// &:before{
				// 	width:1px;
				// 	height:100%;
				// 	transform:translateX(-50%);
				// 	background-color: rgba($dark, 0.08);
				// 	@include position(absolute, rem(6px) null null 50%);
				// 	@include breakpoint(large) {
				// 		content:'';
				// 	}
				// }
				.col{
					margin-bottom:rem(20px);
					@include breakpoint(large) {
						margin-bottom:0;
					}
				}
			}
			.section-headline{
				position: relative;
				font-size: rem(27px);
				margin-bottom:rem(32px);
				@include breakpoint(tiny) {
					font-size: rem(32px);
				}
				@include breakpoint(small) {
					font-size: rem(38px);
				}
				&.fb-headline{
					&:before{
						color:#3b5998;
						content:'\f230';
						font-size: 2.7rem;
						margin-right:rem(15px);
						font-family: $icon-font;
					}

				}
				small{
					font-size: rem(24px);
					@include breakpoint(small) {
						font-size: rem(30px);
					}
				}
			}
			p{
				margin-bottom:rem(38px);
				&.left-text{
					@include breakpoint(large) {
						max-width:rem(520px);
					} 
				}
			}
			.right-col{
				@include breakpoint(tiny) {
					float: right;
					width:100%;
				}
				@include breakpoint(large) {
					width:89%;
				}
			}
			.section-3-btn{
				@include breakpoint(large) {
					margin-left:rem(64px);
				}
			}
			.float-img {
				max-width:rem(197px);
				margin-bottom:rem(10px);
				@include breakpoint(tiny) {
					width:auto;
					float: left;
					margin-bottom:0;
					margin-right:rem(19px);
				}
			}
		}
	}

	.space{
			margin:0rem 0 2.5625rem;
	}

// FOOTER
//////////////////////////////
	.footer {
		display: block;
		.pre-footer{
			color:#c6c6c6;
			position: relative;
			background-color: $dark;
			padding:rem(60px) 0 rem(40px);
			@include breakpoint(tiny) {
				padding:0 0 rem(50px);
			}
			@include breakpoint(large) {
				padding:rem(82px) 0 rem(50px);
			}
			.google-maps{
				z-index: 1;
				display: none;
				height:rem(300px);
				position: relative;
				margin-bottom:rem(80px);
				&.showMap{
					&:before{
						visibility:hidden;
					}
				}
				&:before{
					content:'';
					z-index: 1;
					width:100%;
					height:100%;
					background-color: transparent;
					@include position(absolute, 0 0 0 0);
				}
				@include breakpoint(tiny) {
					display: block;
				}
				@include breakpoint(large) {
					height:100%;
					margin-bottom:0;
					width:calc(50% - 18px);
					@include position(absolute, 0 null 0 0);
				}
				@include breakpoint(giant) {
					width:50%;
				}
				iframe{
					height:100%;
					width:100%;
				}
			}
			.wrapper{
				width:100%;
				.left{
					@include breakpoint(large){
						width:50%;
						float:left;
					}
				}
				hr{
					margin:rem(38px) 0 rem(51px);
					background-color: rgba($light, 0.17);
				}
				span{
					display: block;
					&.footer-headline{
						color:$light;
						font-weight: 700;
						font-size: rem(20px);
						margin-bottom:rem(16px);
						text-transform: uppercase;
						span{
							display: inline-block;
						}
					}
				}
				.col{
					@include breakpoint(giant) {
						padding-left: rem(82px);
					}
					.anfahrt &{
						padding:0;	
					}
				}
				.address{
					display: inline-block;
					margin-right:rem(26px);
					margin-bottom:rem(40px);
				}
				.contacts{
					display: inline-block;
					margin-bottom:rem(40px);
				}
				.weekly{
					display: inline-block;
					margin-right:rem(34px);
					strong{
						display: inline-block;
						margin-bottom:rem(12px);
					}
				}
				.weekend{
					display: inline-block;
					strong{
						display: inline-block;
						margin-bottom:rem(12px);
					}
				}
				a{
					color:#6fc2fa;
					&:hover, &:focus {
						color:$light;
					}
				}
				hr{
					.anfahrt &{
						display: none;
					}
				}
				form{
					input{
						width:100%;
						float: left;
						border:none;
						display: inline-block;
						@include breakpoint(tiny) {
							width:50%;
						}
						@include breakpoint(small) {
							width:60%;
						}
						@include breakpoint(medium) {
							width:70%;
							
						}
						@include breakpoint(large) {
							width:50%;
						}
						@include breakpoint(giant) {
							width:55%;
						}
						&.error{
							border-bottom:3px solid $alert;
						}
					}
					button{
						width:100%;
						float: right;
						display: inline-block;
						@include breakpoint(tiny) {
							width:50%;
						}
						@include breakpoint(small) {
							width:40%;
						}
						@include breakpoint(medium) {
							width:30%;
						}
						@include breakpoint(large) {
							width:50%;
						}
						@include breakpoint(giant) {
							width:45%;
						}
						&:hover, &:focus {
							color:$dark;
							background-color: $light;
						}
					}
					label.error{
						display: none!important;
					}
					fieldset{
						margin-bottom:0;
					}
				}
			}
		}
		.nav-container{
			display: none;
			background-color: $secondary;
			padding:rem(33px) 0 rem(28px);
			@include breakpoint(large) {
				display: block;
			}
		}
		.fb{
			display: none;
			float: right;
			color:$light;
			position: relative;
			font-size: rem(15px);
			padding-left:rem(22px);
			text-transform: uppercase;
			@include breakpoint(giant) {
				display: block;
			}
			&:before{
				content:'';
				width:rem(13px);
				height:rem(13px);
				@include position(absolute, rem(5px) null null 0);
				background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiIFs8IUVOVElUWSBuc19mbG93cyAiaHR0cDovL25zLmFkb2JlLmNvbS9GbG93cy8xLjAvIj5dPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4bWxuczphPSJodHRwOi8vbnMuYWRvYmUuY29tL0Fkb2JlU1ZHVmlld2VyRXh0ZW5zaW9ucy8zLjAvIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjM2cHgiIGhlaWdodD0iMzZweCIgdmlld0JveD0iMCAwIDM2IDM2IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCAzNiAzNiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGRlZnM+PC9kZWZzPjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yLDM2LjFjLTEuMTAxLDAtMi0wLjg5OS0yLTJWMmMwLTEuMTAxLDAuODk5LTIsMi0yaDMyLjFjMS4xMDEsMCwyLDAuODk5LDIsMnYzMi4xYzAsMS4xMDEtMC44OTksMi0yLDJIMnogTTI0Ljg5OSwzNi4xdi0xNGg0LjdsMC43LTUuMzk5aC01LjR2LTMuNWMwLTEuNjAxLDAuNC0yLjcsMi43LTIuN2gyLjlWNS43QzMwLDUuNiwyOC4zLDUuNSwyNi4zLDUuNWMtNC4yLDAtNywyLjUtNyw3LjJ2NGgtNC43VjIyLjFoNC43djE0SDI0Ljg5OXoiLz48L3N2Zz4=);
				background-position:center center;
				background-repeat: no-repeat;
				background-size: contain;
			}
			&:after{
				content:'';
				width:1px;
				height:rem(18px);
				background-color: rgba($light, 0.48);
				@include position(absolute, rem(3px) null null rem(-27px));
			}
			&:hover, &:focus {
				color:$dark;
			}
		}
	}

.escape.email{
    display: inline-block;
    span{
        display: inline-block;
        margin:0;
        top:0;
        padding:0;
        font-weight: normal;
        font-size: rem(18px);
        &:before{
        	content:none;
        }
        span{
            display: inline-block;
        }
    }
}
body{
	&.nojs{
		#content-slider{
			display: none;
		}
		.section-2{
			margin:5.5rem 0 6.5rem;
		}
		.escape.email{
		    display: inline-block;
		    span{
		        display: inline-block;
		        margin:0;
		        top:0;
		        padding:0;
		        font-weight: normal;
		        font-size: rem(18px);
		        &:before{
		        	content:none;
		        }
		        span{
		            display: inline-block;
		        }
		    }
		}
	}
	&.pflege-and-stylingprodukte{
		ul{
			&.styled-list {
				li{
					display:inline-block !important;
					margin-right: rem(12px) !important;
					border:none;
				}
			}
		}
	}
}

.jobbox {
	background-color: white;
	border: opx none;
	border-radius: 2rem;
	padding: 3rem 1rem;
	box-shadow: 5px 5px 39px -20px rgb(190, 195, 197);

	// Und hier so verschachtelm: ;)
	&:marker{
		display: none!important;
		opacity: 0;
	}
}

.needs-padding {
	margin-left: 0rem;
	margin-top: 2rem;
	@include breakpoint(medium) {
		margin-left: 2rem;
		margin-top: 0rem;
	}
}

.needs-padding-b {
	margin-bottom: 1.5rem!important;
}

details > summary:first-of-type {
	display: block;
}