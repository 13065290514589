* {
	margin: 0;
	padding: 0;

	&, &:before, &:after {
		box-sizing: inherit;
	}
}

::selection {
	color:$light;
	background-color: $secondary;
}
::-moz-selection {
	color:$light;
	background-color: $secondary;
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
	background: $light;
	box-sizing: border-box;
	color: $primary;
	font-family: $main-font;
	font-size: 100% * ($base-font-size / 16px);
	hyphens: manual;
	line-height: rem($base-line-height);

	/* disable text resize in landscape. e.g. on iphone */
	text-size-adjust: none;
}

body {
	line-height: rem($base-line-height);

	// Debug mode
	&.the-customer-page #log_hider {
		display: none;
	}

	&.ie-8 #pageloader:after {
		display: none;
	}
}

iframe {
	border:none;
	width: 100%;
}

/**
 * General Margin
 */
p, ol, dl, .margin-bottom {
	margin-bottom: rem($base-line-height);
}

/**
 * Headlines
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	@extend .margin-bottom;
	display: block;
	text-rendering: optimizeLegibility;
}

h1, .h1 {
	line-height: 1.3em;
	font-weight: 500;
	font-size: rem(27px);
	margin-bottom:rem(40px);
	font-family: $display-font;
	text-transform: uppercase;
	@include breakpoint(small) {
		font-size: rem(37px);
	}
	@include breakpoint(medium) {
		line-height: 1em;
		margin-bottom:rem(66px);
		font-size: rem(48px);
	}
	@include breakpoint(large) {
		font-size: rem($h1-size);
	}
	small{
		display: block;
		color:$secondary;
		font-weight: 300;
		line-height: 1em;
		font-size: rem(24px);
		line-height: 1.231em;
		text-transform: none;
		@include breakpoint(small) {
			font-size: rem(32px);
		}
		@include breakpoint(medium) {
			line-height: 1.231em;
			font-size: rem(39px);
		}
	}
}

h2, .h2 {
	color:$secondary;
	line-height: 1.3em;
	margin-bottom:rem(21px);
	font-size: rem($h2-size);
	text-transform: uppercase;
	&.gallery-headline{
		color:$light;
		display: block;
		font-size: rem(18px);
		background-color: $secondary;
		padding:rem(10px) rem($base-gap);
		margin:rem(20px) 0 rem( 50px);
		@include breakpoint(small) {
			font-size: rem(22px);
		}
	}
}

h3, .h3 {
	color:$secondary;
	line-height: 1.3em;
	margin-bottom:rem(21px);
	font-size: rem($h3-size);
	text-transform: uppercase;

	small{
		display: block;
		color:$primary;
		font-weight: 300;
		line-height: 1em;
		font-size: rem(20px);
		line-height: 1.231em;
		text-transform: none;
		@include breakpoint(small) {
			font-size: rem(20px);
		}
		@include breakpoint(medium) {
			line-height: 1.231em;
			font-size: rem(20px);
		}
	}
}


h4, .h4 {
	font-size: rem($h4-size);
}

h5, .h5 {
	font-size: rem($h5-size);
}

h6, .h6 {
	font-size: rem($h6-size);
}

/**
 * Links
 */
a {
	color: $secondary;
	text-decoration: none;
	transition: color 0.3s, background 0.3s ease-in-out;
	&:focus, &:hover, &:active {
		color: $primary;
	}

	img {
		border: none;
	}

	&[href$=".pdf"]:before {
		@extend .fa-default;
		@extend .fa-file-pdf-o;
		margin-right: 0.5em;
	}

	&[href^=tel] {
        color:inherit;
        text-decoration:underline;
    }
}

hr {
	border: none;
	clear: both;
	height: rem(1px);
	background: #c9c9c9;
	margin: rem(41px) 0 rem(41px);

}


ul {
	list-style: none;
	margin-bottom: 0;

	&.styled-list {
		
		margin-bottom: 0;

		li {
			position: relative;
			padding: 1rem 0 1rem 1.3rem;
			
			border-bottom: 1px solid #e2e2e2;
			&:last-child{
				border-bottom:0;
				padding-bottom: 0;	
			}
			&:before {
				content:'\f138';
				color:$secondary;
				font-size: rem(15px);
				font-family: $icon-font;
				position: absolute; 
				top: 17px;
				left: 0;
			}
		}

		ul {
			margin-left: 1rem;
			margin-bottom: 0;
		}
	}

	&.unstyled-list {
		margin-bottom:0;

		li {
			padding:0;
			margin:0;
			border:none;
			&:before {
				content: none;
			}
		}
	}
}

/**
 * Images
 */
img {
	vertical-align: bottom;
}

/*
 * responsive images
 */

img {
    float:none;
    height:auto;
    max-width:100%;
    width:100%;

    &[src^='http://cms.'] {
		max-width:none;
		width:auto;
	}

    [data-rel] &, .lightbox-image & {
        margin-bottom:0;
    }
}

/*
 * reset copyright link
 */
[href*='alpen-web.ch'],
[href*='bewertet.de'],
[href*='euroweb.at'],
[href*='euroweb.de'],
[href*='ew.de'],
[href*='geprueft.de'],
[href*='web2walk.com'],
[href*='webstyle.de'],
[href*='webstyle.com'],
[href*='wn-onlineservice.de'] {
    display:table;
    text-decoration:none;

    img {
        background:none;
        border-radius:0;
        border:none;
        margin-bottom:5px;
        outline:none;
        padding:0;
        white-space:normal;
        width:auto !important;
    }
}

/**
 * Tables
 */
table {
	@extend .margin-bottom;
	border-collapse: collapse;
	width: 100%;

	caption {
		font-size: 1.2rem;
		font-weight: 700;
		padding-bottom: .5rem;
	}
}

// Responsive table styles
table, thead, tbody, th, td, tr, caption {
	display: block;
}

thead {
	border-collapse: collapse;

	tr {
		position: absolute;
		top: -999%;
		left: -999%;
	}
}

tbody tr {
	border-bottom: 1px solid $medium;
	padding: rem($base-gap) 0;
}

td {
	min-height: 1rem;
	padding: .3rem rem($base-gap) .3rem 35%;
	position: relative;

	&:before {
		content: attr(data-label) ": ";
		font-weight: bold;
		left: 1rem;
		position: absolute;
		top: .3rem;
		white-space: nowrap;
		width: 45%;
	}
}

// Restore proper table display values for larger screens
@include breakpoint(small) {
	table {
		display: table;
	}

	caption {
		display: table-caption;
	}

	thead {
		display: table-header-group;
	}

	tbody {
		display: table-row-group;
	}

	tr {
		display: table-row;
	}

	th, td {
		display: table-cell;
		vertical-align: top;
	}

	td {
		min-height: inherit;
		padding: rem($base-gap) 1rem;

		&:before {
			display: none;
			content: "";
			width: auto;
		}
	}

	thead tr {
		position: static;
		left: auto;
		top: auto;
	}
}
