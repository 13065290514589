@import "../vendor/picker";

$form-border-radius: 5px;

div.form-overlay
{
	position: fixed;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,.8);
	z-index:999998;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0;
	transition:800ms all ease-in-out;
}
div.form-overlay.show
{
	opacity:1;
	visibility: visible;
}

form.systemform .fa-close
{
	cursor:pointer;
	position: absolute;
	top: 17px;
	right: 17px;
}

form.systemform {
	width:600px;
	margin:0 auto;
	background:#fff;
	padding:1.125rem;
	appearance:none;
	border-radius:5px;
	box-shadow: 0 0 2px 1px rgba(0,0,0,0.2);
	font: 16px sans-serif;

	position: absolute;
	top: -100%;
	left: 50%;
	transform:translate(-50%, -50%);
	opacity:0;

	transition:800ms all cubic-bezier(0.175, 0.885, 0.320, 1.275);
	z-index:999999;
}
form.systemform.show
{
	top: 50%;
	opacity: 1;
}

@media screen and (max-width: 600px)
{
	form.systemform.show {width:100vw;top:0;left:0;transform:none;}
}

	form.systemform .hidden
	{
		display:none;
	}
	form.systemform .form-header
	{
		padding:20px 0;
	}
		form.systemform .form-header .progress
		{
			height:6px;
			width:70%;
			margin:0 auto;
			background:#eee;
			position: relative;
			border-radius:3px;
			overflow: hidden;
		}
			form.systemform .form-header .progress .progress-value
			{
				position: absolute;
				height:100%;
				width:0%;
				background:$primary;
				transition:all ease-in-out 500ms;
			}
			form.systemform .form-header .percent-value
			{
				font-size:12px;
				text-align: center;
				display: block;
				color:#c3c3c3;
				float: right;
				margin-right: 15%;
				display: none;
			}
	form.systemform fieldset
	{
		border:none;
	}
	form.systemform .fieldset
	{
		height:0;
		max-height:0;
		transition:1500ms max-height ease-in-out, 500ms opacity ease-in-out;
		overflow: hidden;
		opacity:0;
	}
	form.systemform .fieldset.active
	{
		height:auto;
		max-height:9999px;
		opacity:1;
	}
		form.systemform .legend
		{
			text-align: center;
			font-size:1.5rem;
			font-weight:200;
			max-width:90%;
			margin:1.6rem auto 2rem auto;
			letter-spacing: 1px;
			color:#5b5b5b;
			line-height: 2rem;
		}
		form.systemform .form-section
		{
			padding:10px 0;
			margin-bottom: 2px;
			display:flex;
			justify-content: space-between;
			border-bottom:1px solid #eee;
			position: relative;
		}
		form.systemform .form-section.sub
		{
			opacity: 0;
			max-height:0;
			visibility: hidden;
			overflow: hidden;
			transition:500ms all ease-in-out;
			padding:0;
		}
		form.systemform .form-section.sub.show
		{
			visibility: visible;
			max-height: 500px;
			opacity: 1;
			padding: 10px 0;
		}

		@media screen and (max-width : 520px) {
			form.systemform .form-section {flex-flow:wrap column;}
		}

		form.systemform .form-section.toggle-input
		{
			flex-wrap: wrap;
		}
		form.systemform .form-section.toggle-input input[data-special=toggle-input]
		{
			flex-basis: 4%;
		}
		form.systemform .form-section.toggle-input label
		{
			flex-basis: 96%;
		}
		form.systemform .form-section.toggle-input .special-input
		{
			flex-basis: 100%;
			max-height: 0px;
			height:0px;
			border:transparent;
			transition:height ease-in-out 300ms, border ease-in-out 200ms 300ms;
		}
		form.systemform .form-section.toggle-input input[data-special=toggle-input]:checked ~ .special-input
		{
			max-height: 999999px;
			height:36px;
			border:solid 1px #e8e8e8;
		}

	form.systemform .specialfield
	{
	    display: none;
	}

	form.systemform button[disabled]
	{
		opacity: 0;
		visibility: hidden;
		display: none;
	}
	form.systemform button.next,
	form.systemform button.submit
	{
		float: right;
	}
	form.systemform button.prev
	{
		float:left;
	}
/*
	form.systemform button.next,
	form.systemform button.prev,
	form.systemform button.submit,
	form.systemform .btn
	{
		background:$primary;
		border:none;
		padding:10px 20px;
		color:#fff;
		cursor:pointer;
		transition:150ms all ease-in-out;
		font-size:0.8rem;
		// letter-spacing: 1px;
		outline: none;
		font-weight:300
	}
	
	form.systemform button.submit
	{
		background: $primary;
	}
	form.systemform button.next:hover
	{
		background:$primary;
	}
	form.systemform button.submit:hover
	{
		background: $primary;
	}
	form.systemform button.prev
	{
		background: transparent;
		color:$primary;
		padding-left: 0
	}
	form.systemform button.prev:before
	{
		content:"<";
		margin-right: 0.2rem;
		font-family:monospace;
	}
	form.systemform button.prev:after
	{
		content:"";
		width: 0;
		height:2px;
		background:$primary;
		display: block;
		transition:150ms all ease-in-out;
		margin-top: 2px
	}
	form.systemform button.prev:hover:after
	{
		width: 100%;
	}
*/
	form.systemform fieldset.actions
	{
		margin-top: 2rem
	}


	form.systemform input[type=text],
	form.systemform input[type=number],
	form.systemform input[type=password],
	form.systemform input[type=email],
	form.systemform input[type=file],
	form.systemform textarea,
	form.systemform select
	{
		border:1px solid #e8e8e8;
		padding:0 10px;
		font:14px/20px sans-serif;
		width:100%;
		border-radius:3px;
		outline: none;
	}
	form.systemform input[type=text].error,
	form.systemform input[type=number].error,
	form.systemform input[type=password].error,
	form.systemform input[type=email].error,
	form.systemform input[type=file].error,
	form.systemform textarea.error,
	form.systemform select.error
	{
		box-shadow:0 0 1px 1px #FF766A inset;
	}

	form.systemform input[type=file]
	{
		opacity:0;
		width: 100%;
		margin:0 auto;
		visibility: hidden;
		display: none;
	}

	.form-section input[type=checkbox], .form-section input[type=radio]
	{
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 40px;
		z-index: 10;
	}
	form.systemform .form-section-radio label
	{
		font-size: 15px;
		color: #5b5b5b;
		width: 100%;
		float: left;
		position: relative;
		line-height:20px;
	}
	.form-section span.checkbox, .form-section span.radio
	{
		line-height:2rem;
		display: inline-block;
		position: absolute;
		padding-right: 30px;
		right: -6px;
		top: 27px;
	}
	.form-section span.radio
	{
		padding-right: 30px;
		right: 0;
		top: -5px;
	}
	.form-section span.checkbox:before, .form-section span.radio:before
	{
		content:"";
		width:1rem;
		height:1rem;
		display:inline-block;
		border-radius:5px;
		border:2px solid $primary;
		margin-left:5px;
		color:transparent;
		position:absolute;
		top:50%;
		transform:translateY(-50%);
	}
	.form-section input[type=checkbox].error ~ span.checkbox:before, .form-section input[type=radio].error ~ span.radio:before {
		border:solid 2px $primary;
	}

	.form-section-radio
	{
		width: 100%;
		position: relative;
	}
	.form-section span.radio:before
	{
	 	border-radius: 50%;
	 	top: 6px;
		left: -6px;
		transform:none;
	}
	.form-section span.checkbox:after
	{
		font-family:fontawesome;
		content:'\f00c';
		color:$primary;
		display: block;
		background-size: contain;
		position: absolute;
		right: 9px;
		top: -15px;
		opacity:0;
		transition: all ease-in-out 300ms;
	}
	.form-section span.radio:after
	{
		content:"";
		width:7px;
		height:7px;
		background:$primary;
		display: block;
		background-size: contain;
		position: absolute;
		right: 19px;
		top: 11px;
		opacity:0;
		transition: all ease-in-out 300ms;
		border-radius:50%;
	}
	.form-section input[type=checkbox]:checked ~ span.checkbox:after , .form-section input[type=radio]:checked ~ span.radio:after
	{
		opacity: 1;
	}

	form.systemform textarea
	{
		resize:vertical;
		padding-top:10px;
		padding-bottom:10px;
		width:100%;
		height:auto;
	}

	form.systemform input[type=text],
	form.systemform input[type=password],
	form.systemform input[type=email],
	form.systemform input[type=file],
	form.systemform select
	{
		height:36px;
		line-height:36px;
	}
	form.systemform option
	{
		padding:0.4rem 0.3rem;
	}
	form.systemform .form-section > label
	{
		color:#5b5b5b;
		height:36px;
		line-height:36px;
		font-size:14px;
		width:100%;
	}
	form.systemform .form-section label.filelabel
	{
		color:#287cdd;
		position: relative;
		padding-left:1.5rem;
		cursor:pointer;
	}
	form.systemform .form-section label.filelabel:before
	{
		content:"+";
		font-size:2rem;
		position: absolute;
		left: 0;
	}
	form.systemform label small
	{
		line-height:0px;
	}
	form.systemform .error-message
	{
		width:100%;
		margin-top:10px;
		border-radius: 5px;
		padding:0;
		color:#FF766A;
		line-height: 22px;
		font-size:14px;
		text-align: center;
		overflow: hidden;
		max-height:0;
		transition: 300ms all ease-in-out;
	}
	form.systemform .error-message.show
	{
		max-height:100px;
	}
	form.systemform fieldset[data-valid=true] .error-message
	{
		display:none;
	}

	form.systemform .flexwrap
	{
		flex-wrap:wrap;
	}
		form.systemform .flexwrap .btn
		{
			width: 24px;
			height: 24px;
			text-align: center;
			line-height: 24px;
			margin-left: 0.5rem;
			padding:0;
			display: inline-block;
		}
	form.systemform .filelist
	{
		flex-basis:100%;
		margin-bottom: 1rem;
		list-style-position: inside;
		color:#5b5b5b;
		line-height: 20px;
		margin-left: 26px;
	}


form.systemform .error-message.show + .legalNotice
{
	margin-top: 1rem;
}

form.systemform .legalNotice
{
	text-align: center;
	font-size:14px;
	margin:0 auto;
	margin-bottom: 1rem;
	max-width:80%;
	color:#5b5b5b;
	max-height:0;
	overflow: hidden;
	transition:500ms all ease-in-out;
	line-height: 20px;
}
form.systemform .legalNotice.show
{
	max-height:100px;
}
form.systemform a
{
	color:#287cdd;
}


.cssload-thecube {
	width: 26px;
	height: 26px;
	margin: 10rem auto;
	position: relative;
	transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	position: relative;
	transform: rotateZ(45deg);
}
.cssload-thecube .cssload-cube {
	float: left;
	width: 50%;
	height: 50%;
	position: relative;
	transform: scale(1.1);
}
.cssload-thecube .cssload-cube:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $primary;
	animation: cssload-fold-thecube 2.76s infinite linear both;
	transform-origin: 100% 100%;
}
.cssload-thecube .cssload-c2 {
	transform: scale(1.1) rotateZ(90deg);
}
.cssload-thecube .cssload-c3 {
	transform: scale(1.1) rotateZ(180deg);
}
.cssload-thecube .cssload-c4 {
	transform: scale(1.1) rotateZ(270deg);
}
.cssload-thecube .cssload-c2:before {
	animation-delay: 0.35s;
}
.cssload-thecube .cssload-c3:before {
	animation-delay: 0.69s;
}
.cssload-thecube .cssload-c4:before {
	animation-delay: 1.04s;
}

@keyframes cssload-fold-thecube {
	0%, 10% {
		transform: perspective(49px) rotateX(-180deg);
		opacity: 0;
	}
	25%,
				75% {
		transform: perspective(49px) rotateX(0deg);
		opacity: 1;
	}
	90%,
				100% {
		transform: perspective(49px) rotateY(180deg);
		opacity: 0;
	}
}




