// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);
// Ostrichsans black
@include font-face('ostrichsans-black',
    'ostrichsans-black.woff',
    'ostrichsans-black.ttf',
    'ostrichsans-black.svg',
    'ostrichsans-black.eot'
);
// Ostrichsans bold
@include font-face('ostrichsans-bold',
    'ostrichsans-bold.woff',
    'ostrichsans-bold.ttf',
    'ostrichsans-bold.svg',
    'ostrichsans-bold.eot'
);