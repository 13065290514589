.clear {
	clear: both;
	float: none;
}

.clearfix {
	@include pie-clearfix;
}

.hidden {
	display: none;
}

.hide-text {
    @include hide-text();
    display: inline-block;
    overflow: hidden;
    width:0;
}

.float-left {
	float: left;
}

img.float-left {
	margin-right:1rem;
}

.float-right {
	float: right;
}

img.float-right {
	margin-left:1rem;
}

.fluid {
	height:auto;
	width:100%;
}

.non-fluid {
    width:auto!important;
}

.align-left {
	text-align: left;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.wide {
	width: 100%;
	display: block;
}

/* 16:9 video resolutions */
.video-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;

    iframe {
        border:none;
        height: 100%;
        position: absolute;
        width: 100%;
    }
}

.lightbox-inline {

}

.lightbox-external {

}
/* default colorbox hover */

*[data-rel="lightbox"],
*[data-rel="lightbox:"],
*[data-rel="lighbox"],
*[data-rel="colorbox"],
.cboxElement,
.lightbox-image {
    width:100%;
    display:table;
    position:relative;
    text-decoration:none;
    margin-bottom:rem($base-gap);
    &.float-img, .float-img-wrapper &{
        @include breakpoint(tiny) {
            width:auto;
        }
        @include breakpoint(small) {
            margin-right:rem($base-gap*2);
            float: left;
        }
        @include breakpoint(medium) {
            width:100%;
            float: none;    
            margin-right:0;
        }
        @include breakpoint(larger) {
            width:auto;
            float: left;
            margin-right:rem($base-gap*2);
        }
    }

    @include breakpoint(tiny) {
        margin-bottom:rem($base-gap*2);
    }
    &[href*=qr] {
        width:auto;

        &:before,
        &:after {
            content:none;
        }
    }

    &:before, &:after {
        @extend %animated-transform;
    }

    &:before {
        bottom:0;
        color:rgba($light, .75);
        content:'+';
        display:block;
        font-family:sans-serif;
        font-size:2rem;
        height:2em;
        line-height:1.8;
        position:absolute;
        right:0;
        text-align:center;
        text-shadow:rem(1px) rem(1px) rem(1px) rgba($dark, .8);
        transform:translate(0, 0);
        width:2em;
        z-index:1;
    }

    &:after {
        bottom:0;
        content:'';
        display:block;
        left:0;
        opacity:0;
        position:absolute;
        right:0;
        top:0;
        z-index:0;
    }

    &:hover, &:focus {

        &:before {
            bottom:50%;
            font-size:3rem;
            height:1.5em;
            line-height:1.5;
            right:50%;
            transform:translate(50%, 50%);
            width:1.5em;
        }

        &:after {
            opacity:.5;
        }
    }
}

body {
    &:before {
        $content: 'default:' + $bp-context;
        width:str_length($content) * 6pt + $base-gap * 2;

        @each $point, $width in $breakpoints {
            $content: $content + '....' + $point + ':' + $width;

            @include breakpoint($point) {
                width:str_length($content) * 6pt + $base-gap * 2;
            }
        }

        content: '#{$content}';
        display:none !important; /* Prevent from displaying. */
    }

    &.devmode:before, &.debug:before {
        background:$alert;
        border-color:$alert;
        border-style:solid;
        border-width:0 rem($base-gap);
        color:$light;
        display:block !important;
        font-family: Courier;
        font-size: 10pt;
        left:0;
        line-height: 2.5;
        overflow:hidden;
        position:absolute;
        right:0;
        text-align: center;
        top:0;
        white-space:nowrap;
        z-index:99999;
    }
}

// Animations
%animated-transform {
    transition: 300ms;
}