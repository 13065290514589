// * Usage: @include pie-clearfix();
@mixin pie-clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
    zoom:1;
}

// * Hides text in block or inline-block elements
// * Usage: @include hide-text();
// * $hide-text-direction is defined in _config.scss
@mixin hide-text($direction: $hide-text-direction) {
  @if $direction == left {
    $wider-than-any-screen: -9999em;
    text-indent: $wider-than-any-screen;
    overflow: hidden;
    text-align: left;
  }
  @else {
    text-indent: 110%;
    white-space: nowrap;
    overflow: hidden;
  }
}

// * Appends the relative path to the images folder to the given URL
// * Usage: background-image: image-url("bg-body.jpg")
// * $image-url-path is defined in _config.scss
@function image-url($url) {
  @return url("#{$image-url-path}#{$url}");
}
//Burbon Helpers

@function is-length($value) {
  @return type-of($value) != "null" and (str-slice($value + "", 1, 4) == "calc"
       or index(auto inherit initial 0, $value)
       or (type-of($value) == "number" and not(unitless($value))));
}

@function unpack($shorthand, $length: 4) {
  @for $i from 1 through $length {
    @if (length($shorthand) < $i) {
      $shorthand: append($shorthand, nth($shorthand, floor($i / 2)));
    }
  }
  @return $shorthand;
}

@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $coordinates: unpack($coordinates);

  $offsets: (
    top:    nth($coordinates, 1),
    right:  nth($coordinates, 2),
    bottom: nth($coordinates, 3),
    left:   nth($coordinates, 4)
  );

  position: $position;

  @each $offset, $value in $offsets {
    @if is-length($value) {
      #{$offset}: $value;
    }
  }
}