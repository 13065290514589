.page-navi {
	// Off-canvas navigation
	//////////////////////////////
		@media screen and (max-width: 991px) {
			top: 0;
			left: 0;
			width: 80%;
			z-index: 100;
			min-height: 100%;
			position: absolute;
			background: $light;
			transition: transform 0.3s ease-in-out;
			transform: translateX(-100%);
			@include breakpoint(tiny) {
				width:rem(320px);
			}
			.col{
				padding:0;
			}
			.branding{
				display: none;
			}
			.mobileNavInfo{
				line-height: rem(27px);
				padding:1rem rem($base-gap);
				margin-top:rem(2*$base-gap);
				background-color:rgba($medium, 0.2);
				>span{
					color:$primary;
					display: block;
					a{
						text-decoration: none;
					}
				}
				.call{
					margin-bottom:15px;
				}
				.weekly, .weekend{
					span{
						display: block;
					}
				}
				.weekly{
					margin-bottom:rem(10px);
				}
			}
			.navi-main {
				li {
					display: block;
					padding:rem(8px) 0;
					border-bottom:1px solid $primary;
					&:last-child{
						border:none;
					}
					&:hover, &:focus, &.active {
						>a{
							color:$secondary;
						}
					}
					a {
						color:$medium;
						display: block;
						font-weight: normal;
						font-size: rem(16px);
						font-family: $main-font;
						text-transform: uppercase;
						padding-left:rem($base-gap);
					}
				}
				.sub {
					li{
						a{
							padding-left:rem($base-gap*2);
						}
					}
				}
			}
		}
		
	// Desktop navigation
	//////////////////////////////
		@include breakpoint(large) {
			top:0;
			left:0;
			right: 0;
			background: $light;
			position: absolute;
			padding:rem(27px) 0;
			z-index: 10;
			transition: box-shadow 0.3s ease-in-out;
			&.fixed{
				top:0;
				position: fixed;
				box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.4);
				.navi-main{
					padding:rem(13px) 0;
				}
				.branding{
					top:0;
					max-width:9.125rem;
				}
			}
			.mobileNavInfo{
				display: none;
			}
			.branding{
				position: relative;
				transition: all 0.3s ease-in-out;
				@include breakpoint(large) {
					transform:translateX(-50%);
					@include position(absolute, 0 null null 51%);
				}
			}
			.navi-main {
				padding:rem(23px) 0;
				transition: padding 0.3s ease-in-out;
				.iexplore &{
					transition: none;
				}
				&.left{
					float: left;
				}
				&.right{
					float: right;
				}
				li {
					display: inline-block;
					margin: 0 rem(10px);
					@include breakpoint(giant) {
						margin: 0 rem(15px);
					}
					&:first-child, &.service{
						margin-left:0;
					}
					&:last-child, &.kontakt{
						margin-right:0;
					}
					&:hover, &:focus, &.active {
						>a{
							color:$secondary;
						}
					}
					a {
						color:$medium;
						font-weight: normal;
						font-size: rem(14px);
						font-family: $main-font;
						text-transform: uppercase;
						@include breakpoint(giant) {
							font-size: rem(16px);
						}
					}
					&.startseite, &.impressum, &.datenschutzerklaerung, &.anfahrt, &.ueber-uns, &.aktuelles-jobs, &.links{
						display: none;	
					}
				}
				.hasSub{
					position: relative;
					&:hover, &:focus{
						.sub{
							opacity:1;
							visibility:visible;
							pointer-events:all;
							&:before{
								content:'';
								opacity:0;
								width:100%;
								bottom: 100%;
								height:rem(27px);
								position: absolute;
								background-color:transparent;
							}
							li{
								opacity:1;
								visibility:visible;
								transform:translateY(0px);
								@for $i from 1 through 3 {
							      	&:nth-child(#{$i+1}){
							        	transition-delay:#{'0.'}#{$i+1}s;
							      	}
							  	}
							}
						}
					}
				}
				.sub {
					left:50%;
					opacity: 0;
					top:rem(45px);
					width:rem(250px);
					visibility:hidden;
					position: absolute;
					pointer-events:none;
					transform:translateX(-50%);
					transition: all 0.3s ease-in-out;
					.iexplore &{
						transition:none;
					}
					li {
						opacity:0;
						display: block;
						visibility:hidden;
						border-bottom:2px solid transparent;
						transform:translateY(50px);
						transition: all 0.3s ease-in-out;
						&:before{
							display:none;
						}
						&:hover, &:focus, &.active{
							a{
								color:$light;
								background-color:$dark;   
							}
						}
					}
					a {
						display: block;
						color:$light;  
						font-size:rem(14px);
						background-color:$secondary;
						padding:rem(10px) rem(16px); 
						transition: color 0.3s, background 0.3s ease-in-out;
					}
				}
			}
		}
}

// FOOTER NAVIGATION
//////////////////////////////
	.navi-add{
		display: none;
		width:100%;
		float: left;
		display: flex;
		justify-content:space-between;
		@include breakpoint(giant) {
			width:78%;
		}
		li{
			display: inline-block;
			a{
				color:$light;
				display: block;
				font-weight: 400;
				font-size: rem(15px);
				font-family: $main-font;
				text-transform: uppercase;
			}
			&:hover, &:focus, &.active {
				a{
					color:$dark
				}
			}
		}
	}

.toggle-navi, .close-navi {
	z-index: 10;
	color:$light;
	top:rem($base-gap);
	left:rem($base-gap);
	position: fixed;
	font-size: rem(32px);
	text-decoration: none;
    border-radius: rem(3px);
	background-color:$secondary;
	padding: rem(14px) rem(9px);
    transition: color 0.3s, background 0.3s ease-in-out;
	&:before {
		@extend .fa-bars;
		font-family: $icon-font;
	}
	&:hover {
		color: $light;
		background-color:$secondary;
	}
	@include breakpoint(large) {
		display: none;
	}
}
.close-navi {
	display: none;
}
.navi-buttons {
	float: left;
}
#navi-toggled:target {
	.page-navi {
		transform: none;
	}
	.page-wrap {
		top:0;
		width:100%;
		position: fixed;
		transform: translateX(80%);
		@include breakpoint(tiny) {
			transform: translateX(320px);
		}
	}
	.close-navi {
		left:rem(11px);
		display: block;
		position: absolute;
		@include breakpoint(tiny) {
			left:rem($base-gap);
		}
		&:before {
			@extend .fa-close;
		}
	}
	.toggle-navi {
		display: none;
	}
	@include breakpoint(large) {
		.close-navi {
			display: none;
		}
		.page-wrap {
			transform: none;
			position: relative;
		}
	}
}